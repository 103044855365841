
.main-contnet {
    width: calc(100vw - 265px);
    position: relative;
    margin-left: 241px;
    margin-top: 60px;
    }
  .content{
      /* width: 100vw; */
      min-height: 158px;
      background: #3D3D3D 0% 0% no-repeat padding-box;
      margin-right: 31px;
      margin-left: 31px;
    }
    .title {
      padding-top: 21px;
      margin-left: 30px;
      text-align: left;
      color: #FFFFFF;
      text-transform: uppercase;
    }
    .hrline {
      margin: 0 25px 0px 30px;
      border: 1px solid #CACACA;
    }
    .filter {
      padding-top: 18px;
      /* margin-left: 275px; */
      color: #FFFFFF;
      text-align: left;
    }
    .filter input {
      background: #646464;
      border-radius: 8px;
      color: white;
      padding: 5px;
      font-size: 12px;
      border: 1px solid #646464;
    }
    .filter button{
      margin: 0 5px;
      /* background: #646464 0% 0% no-repeat padding-box; */
      border-radius: 8px;
    }
    .tiles {
    padding: 5px;  
    margin-top: 23px;
    height: 55px;  
    background: #3D3D3D 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 8px #0000001A;
    border-radius: 3px;
    }
    .cardtitle {
      font-size: 16x;
      font: Montserrat;
      margin: 13px 12px;
      text-align: left;
      color: #FFFFFF;
    }
  
    .tiles span{
      font-size: 25px;
      float: right;
      text-align: right;
      color: #36E33C;
      margin-top: -9px;
    }
    
    .chartcard {
      padding: 10px 0;
      margin-top: 20px;
      background: #3D3D3D 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 8px #0000001A;
    border-radius: 8px;
    }
    .buttonfilter {
      padding: 5px 15px;
      margin-right: 15px;
      float: right;
      background: #646464 0% 0% no-repeat padding-box;
    border-radius: 5px;
    color: #FFFFFF;
    font-size: 12px;
    }
    .hedingtitle {
      color: #FFFFFF;
      padding: 15px 0 16.5px 20px;
    }
    .titleline {
      border-bottom: solid 1px #FFFFFF;
      padding-top: 5px;
    }
  
    .passesAlign{
        background: #3D3D3D;
        margin: 25px 30px;
        border-radius: 8px;
    }
  
    .dailyentries, .dailypasses{
      background: #3D3D3D;
      border-radius: 8px;
      padding-bottom: 0.5px;
    }
  
  .passesAppliedMain, .dailyentriesmain, .dailypassesmain{
      padding: 0 0 0 20px;
  }
  
  .btn-group select {
    background: #646464 0% 0% no-repeat padding-box;
  border: 1px solid #FC6157;
  border-radius: 3px;
    color: white; /* White text */
    padding: 6px 0px; /* Some padding */
    cursor: pointer; /* Pointer/hand icon */
    float: left; /* Float the buttons side by side */
    margin: 10px;
    height: 35px;
    min-width: 180px;
  }

  .btn-group input {
    background: #646464 0% 0% no-repeat padding-box;
  border: 1px solid #FC6157;
  border-radius: 3px;
    color: white; /* White text */
    margin-top: 10px;
    height: 35px;
  }
  
  .btn-group:after {
    content: "";
    clear: both;
    display: table;
  }
  
  
  .export-btn button{
    margin-top: 36px;
    margin-right: 6px;
    width: 94px;
    height: 35px;
    float: right;
    background: #ED3125 0% 0% no-repeat padding-box;
    border-radius: 3px;
    border: 0;
    color: #FFFFFF;
    opacity: 1;
  }
  
  .tooltiptext {
    /* visibility: hidden; */
    /* width: 120px; */
    /* background-color: black; */
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
    
    /* Position the tooltip */
    position: absolute;
    z-index: 1;
    top: 100%;
    /* left: 50%; */
    margin-top: -4px;
    margin-left: -190px;
  }

  .react-datepicker__input-container input{
    margin-top: 10px !important;
    height: 35px !important;
    border: 1px solid #FC6157  !important;
    border-radius: 3px !important;
  }

  .tabledropdaown {
    background: #333333;
    color: #FC6157;
    border-radius: 3px;
    height: 30px;
    min-width: 70px;
    max-width: 98px;
    border: 1px solid #333333;
  }